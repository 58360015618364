import SiteConfig from "./SiteConfig";

export default {
  title: SiteConfig.siteTitle,
  titleTemplate: `${SiteConfig.siteTitle} | %s`,
  description:
    "This is a collection of finance tools, available to help you on your journey to financial freedom!",
  additionalLinkTags: [{ rel: "icon", href: "/logo.webp" }],
  // TODO: finish this stuff - https://github.com/garmeeh/next-seo#nextseo-options
  //   openGraph: {
  //     type: "website",
  //     locale: "en_IE",
  //     url: "https://www.url.ie/",
  //     siteName: "SiteName",
  //   },
  //   twitter: {
  //     handle: "@handle",
  //     site: "@site",
  //     cardType: "summary_large_image",
  //   },
};
