import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import { ALL_GUIDES, ALL_TOOLS } from "../constants";

export default function Navbar() {
  const router = useRouter();

  return (
    <div className="text-lg p-4 flex">
      <Link href="/">
        <Image
          src="/logo-words-white.webp"
          alt="money meister logo"
          width="100"
          height="100"
        />
      </Link>
      <div className="flex divide-x items-center">
        <Link
          href="/about"
          className={`px-4 ${
            router.pathname == "/about" ? "text-amber-600" : ""
          } hover:text-amber-600`}
        >
          About Us
        </Link>
        <div className="group">
          <Link
            href="/tools"
            className={`px-4 ${
              router.pathname == "/tools" ? "text-amber-600" : ""
            } hover:text-amber-600`}
          >
            Tools
          </Link>
          <div className="hidden group-hover:block absolute z-[20]">
            <ul className="mt-2 px-2 grid grid-cols-1 md:grid-cols-4 gap-1 bg-slate-900 p-2 rounded-md divide-x">
              {ALL_TOOLS.map((tool) => (
                <Link
                  key={tool.name}
                  href={tool.url}
                  className={`py-1 px-2 flex ${
                    router.pathname == tool.url ? "text-amber-600" : ""
                  } hover:text-amber-600`}
                >
                  {tool.name}
                </Link>
              ))}
            </ul>
          </div>
        </div>
        <div className="group">
          <Link
            href="/guides"
            className={`px-4 ${
              router.pathname == "/guides" ? "text-amber-600" : ""
            } hover:text-amber-600`}
          >
            Guides
          </Link>
          <div className="hidden group-hover:block absolute z-[20]">
            <ul className="mt-2 px-2 grid grid-cols-1 md:grid-cols-4 gap-1 bg-slate-900 p-2 rounded-md">
              {ALL_GUIDES.map((guide) => (
                <Link
                  key={guide.name}
                  href={guide.url}
                  className={`py-1 px-2 flex ${
                    router.pathname == guide.url ? "text-amber-600" : ""
                  } hover:text-amber-600`}
                >
                  {guide.name}
                </Link>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
