import React from "react";
import Footer from "./common/footer";
import Navbar from "./common/navbar";

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <div className="bg-slate-700 min-h-screen flex flex-col text-white min-w-fit">
      <Navbar />
      <div className="md:px-4 flex justify-center">{children}</div>
      <Footer />
    </div>
  );
}
